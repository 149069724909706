$( document ).ready( function() {
	if ( $( '.acf-map' ).length > 0 ) {
		render_map( $( '.acf-map' ) );
	}
} );

function render_map( $el ) {
	// var
	const $markers = $el.find( '.marker' );

	// vars
	const args = {
		// center		: new google.maps.LatLng(0, 0),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 2,
		mapTypeControl: false,
		disableDefaultUI: true,
	};

	// create map
	const map = new google.maps.Map( $el[ 0 ], args );

	map.set( 'styles', [
		{
			featureType: 'all',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels',
			stylers: [
				{
					saturation: '-100',
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.fill',
			stylers: [

				{
					color: '#FFFFFF',
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					visibility: 'off',
				},
				{
					color: '#26425F',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#26425F',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#26425F',
				},
				{
					lightness: 17,
				},
				{
					weight: 1.2,
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{
					color: '#26425F',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#4E7483',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#4E7483',
				},
			],
		},
		{
			featureType: 'landscape.natural',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#4E7483',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					lightness: 21,
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#4E7483',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#4E7483',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#95ADB5',
				},
				{
					weight: 0.2,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#26425F',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#26425F',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#95ADB5',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'geometry',
			stylers: [
				{
					color: '#93ACB4',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [
				{
					color: '#26425F',
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#26425F',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#26425F',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#26425F',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	]
	);

	// add a markers reference
	map.markers = [];

	// add markers
	$markers.each( function() {
	    add_marker( $( this ), map );
	} );
	center_map( map );
	$( window ).on( 'resize load', function() {
		// center map
		center_map( map );
	} );
}

function add_marker( $marker, map ) {
	// var
	const latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );

	// create marker
	const icon = {
		path: 'M22,0C9.8,0,0,10.6,0,23.8C0,41.6,22,68,22,68s22-26.4,22-44.2C44,10.6,34.2,0,22,0z M22,30c-4.4,0-8-3.4-8-7.5 s3.6-7.5,8-7.5s8,3.4,8,7.5S26.4,30,22,30z',
		fillColor: '#FFFFFF',
		fillOpacity: 1,
		anchor: new google.maps.Point( 22, 68 ),
		strokeWeight: 0,
		scale: 1,
	};

	// create marker
	const marker = new google.maps.Marker( {
		position: latlng,
		map,
		draggable: false,
		icon,
	} );

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if ( $marker.html() ) {
		// create info window
		const infowindow = new google.maps.InfoWindow( {
			content: $marker.html(),
		} );

		// show info window when marker is clicked
		google.maps.event.addListener( marker, 'click', function() {
			infowindow.open( map, marker );
		} );
	}
}

function center_map( map ) {
	// vars
	const bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ) {
		const latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
		bounds.extend( latlng );
	} );

	// More than 1 marker
	if ( map.markers.length > 1 ) {
		// set center of map
		    map.setCenter( bounds.getCenter() );
		    map.setZoom( 9 );
	} else {
		// fit to bounds
		map.setCenter( bounds.getCenter() );
		map.setZoom( 9 );
	}
}
